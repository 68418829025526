







import { Vue, Component, Prop } from 'vue-property-decorator';
import { likeModule } from '@/store';
import { CONST } from '@/config/config';

@Component
export default class LikeShare extends Vue {
  @Prop({
    required: true
  })
  public type!: any;

  // public likeStatus(state, id) {
  //   if (!this.isLogin) {
  //     this.$q.notify('로그인이 필요합니다.');
  //     this.$modal.show('login');
  //   } else {
  //     const params = {
  //       target: this.$route.name === 'product' ? 'product' : 'place',
  //       target_id: id
  //     }
  //     if (state) {
  //       likeModule.fetchLikeDelete(params).then((res) => {
  //         if (res.status) {
  //           this.$q.notify('좋아요를 취소하셨습니다.');
  //           this.type.like_state = !this.type.like_state;
  //         }
  //       });
  //     } else {
  //       likeModule.fetchLike(params).then((res) => {
  //         if (res.status) {
  //           this.$q.notify(this.$route.name === 'product' ? '티켓을 좋아요 하였습니다.' : '매장을 좋아요 하였습니다.');
  //           this.type.like_state = !this.type.like_state;
  //         }
  //       });
  //     }
  //   }
  // }
  get copyContents() {
    if (this.type.share_info) {
      return this.type.share_info.body;
    }
    return null;
  }
  public copyNoti() {
    if (this.$route.name === 'place') {
      this.$q.notify({
        message: '매장 정보가 클립보드에 저장되었습니다. <br>원하는 곳에 붙여넣기 하세요.',
        html: true
      });
    } else {
      this.$q.notify({
        message: '티켓 정보가 클립보드에 저장되었습니다. <br>원하는 곳에 붙여넣기 하세요.',
        html: true
      });
    }
  }

  // get isLogin() {
  //   return authModule.isLogin;
  // }
}
